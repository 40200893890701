<template>
  <div class="more_raising_cattle">
    <div class="cattle_header"></div>
    <div class="cattle_body">
      <p>1、养牛道具可通过完成任务来领取，任务形式包括但不限于签到、阅读、分享、点赞、评论、打赏资讯、关注作者；</p>
      <p></p>
      <p>2、牛肉兑换券可以兑换任意产品组合，数量有限，先到先得！</p>
      <p>3、胜龙微信商城有定位系统，可以查看广州、深圳、东莞、佛山四个城市的门店位置，用户登录小程序商城后可选择以下方式兑换有关产品：</p>
      <p>（1）线下实体门店领取；</p>
      <p>（2）同城配送，需自行承担运费；</p>
      <p>（3）异地配送，需自行承担运费。</p>
      <p>4、在养牛期间，用户可以选择中途暂停小牛养成，系统将会把小牛养成的经验值自动结算成凯迪乡村积分，牛犊等级越高，损耗越少。积分可用于凯迪乡村积分商城交换礼品。</p>
      <p>
        5、平台依法运营此次活动，如遇因不可抗力、相关情势变更等情况导致本活动暂停举办或无法举办的，平台有权利根据相关法律法规的规定主张免责不可抗力或相关情势变更包括但不限于：活动受到第三方各种不当手段攻击、严重网络攻击或因系统故障、行政机关政策要求、或遇到重大灾害事件导致活动无法举办、暂停或需要调整的。
      </p>
      <p>
        6、每个用户仅限兑付一次，不得以任何违反诚实信用、不正当手段或舞弊方式参与本活动。一经发现，本平台有权采取限制登录、限制参与活动、封禁账号、撤消活动资格、限制领取券、限制再次注册使用等措施，亦有权收回、追讨、限制兑换已领奖励、联动商家停止发货，并保留追究该用户责任的权利。如因此给用户造成损失，本平台不进行任何赔偿或补偿。
      </p>
    </div>
    <div class="cattle_footer">
      <div class="back_btn" @click="$router.push('/cattlestrategy')"></div>
    </div>
    <!-- <div class="back_btn_box">
      <div class="back_btn"></div>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {},
}
</script>

<style scoped lang="less">
.more_raising_cattle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #107c3f;
  overflow-y: scroll;
  .cattle_header {
    width: 100%;
    height: 38px;
    margin-top: 10px;
    background: url('http://cdn.9kd.com/kdnet/cattle_more_header.png') no-repeat;
    background-size: 100%;
  }
  .cattle_body {
    width: 100%;
    padding: 10px 0;
    background: url('http://cdn.9kd.com/kdnet/cattle_more_body.png') repeat-y fixed;
    background-size: 100%;
    text-align: left;
    p {
      padding: 0 20px;
      color: #399848;
      font-size: 14px;
      line-height: 25px;
      text-align: left;
    }
  }
  .cattle_footer {
    width: 100%;
    height: 163.5px;
    background: url('http://cdn.9kd.com/kdnet/cattle_more_footer.png') no-repeat;
    background-size: 100%;
    background-position: -2px 0;
    .back_btn {
      width: 200px;
      height: 40px;
      margin: 100px auto 0 auto;
      background: url('http://cdn.9kd.com/kdnet/back_to_cattle_btn.png') no-repeat;
      background-size: 100%;
    }
  }
  //   .back_btn_box {
  //     width: 100%;
  //     height: 80px;
  //     .back_btn {
  //       width: 200px;
  //       height: 40px;
  //       margin: 20px auto 0 auto;
  //       background: url('http://cdn.9kd.com/kdnet/back_to_cattle_btn.png') no-repeat;
  //       background-size: 100%;
  //     }
  //   }
}
</style>
