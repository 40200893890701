<template>
  <div class="rural_strategy">
    <img src="http://cdn.9kd.com/kdnet/rural_strategy_img01.png" alt="" />
    <img src="http://cdn.9kd.com/kdnet/rural_strategy_img02.png" alt="" />
<!--    <img src="http://cdn.9kd.com/kdnet/rural_strategy_img03.png" alt="" />-->
    <div class="last_box">
      <img src="http://cdn.9kd.com/kdnet/rural_strategy_img04.png" alt="" />
      <img class="btn" @click="$router.push('/ruralrule')" src="http://cdn.9kd.com/kdnet/rural_strategy_btn.png" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
}
</script>

<style scoped lang="less">
.rural_strategy {
  height: 100vh;
  overflow-y: scroll;
  background-color: #4a8c28;
  img {
    width: 100%;
    display: block;
  }
  .last_box {
    position: relative;
    width: 100%;
    .btn {
      position: absolute;
      bottom: 40px;
      left: 50%;
      width: 236px;
      margin-left: -118px;
    }
  }
}
</style>
