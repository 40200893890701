<template>
  <div class="raising_cattle">
    <img src="http://cdn.9kd.com/kdnet/raising_cattle_01.png" alt="" />
    <img src="http://cdn.9kd.com/kdnet/raising_cattle_02.png" alt="" />
    <img src="http://cdn.9kd.com/kdnet/raising_cattle_03.png" alt="" />
    <div class="last_box">
      <img src="http://cdn.9kd.com/kdnet/raising_cattle_04.png" alt="" />
      <img class="btn" @click="moreStrategy" src="http://cdn.9kd.com/kdnet/raising_cattle_btn.png" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    moreStrategy() {
      this.$router.push('/morestrategy')
    },
  },
}
</script>

<style scoped lang="less">
.raising_cattle {
  height: 100vh;
  overflow-y: scroll;
  background-color: #0d7d40;
  img {
    width: 100%;
    display: block;
  }
  .last_box {
    position: relative;
    width: 100%;
    .btn {
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 200px;
      margin-left: -100px;
    }
  }
}
</style>
