import { render, staticRenderFns } from "./Morestrategy.vue?vue&type=template&id=ea9b0804&scoped=true"
import script from "./Morestrategy.vue?vue&type=script&lang=js"
export * from "./Morestrategy.vue?vue&type=script&lang=js"
import style0 from "./Morestrategy.vue?vue&type=style&index=0&id=ea9b0804&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea9b0804",
  null
  
)

export default component.exports