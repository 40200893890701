<template>
  <div class="more_raising_cattle">
    <div class="cattle_header"></div>
    <div class="cattle_body">
      <p>更新日期：2021年【9】月【18】日</p>
      <p></p>
      <p>
        参与本次乡村振兴活动（以下简称“本活动”）前，请用户详细阅读活动规则及相关条款，尤其免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。凡参与本次活动，则视为用户已阅读、理解并同意活动规则及声明全部内容。
      </p>
      <p><br /></p>
      <p>一、活动玩法</p>
      <p>
        活动期间，用户登录客户端首次进入凯迪乡村振兴活动页，选择想要参与的种植、养殖、作坊加工类活动，例如（领养小牛、种植稻米等），可通过完成相应任务（包括每日签到、阅读资讯、浏览广告、打赏、邀请好友助力等）获得活动道具（例如养牛道具、宝箱道具、种植道具等），待活动达到成熟收获阶段后（例如养牛成熟、稻米丰收阶段），用户即可获得1份奖励的兑换优惠券（以下简称“优惠券“，使用优惠券根据实际情况使用（具体以活动页面要求为准），引导到供应商的线上商城使用。完成奖励的兑换后，活动期间可继续参与乡村活动。
      </p>
      <p><br /></p>
      <p>1.1选择参与的活动</p>
      <p><br /></p>
      <p>用户进入乡村振兴页可自行选择想要的活动项目，养殖、种植、作坊类型以页面实际展示为准，成熟后用户可根据当前奖励进行兑换，因为部分活动奖励会有限量，兑换奖励以页面实际展示为准。</p>
      <p><br /></p>
      <p>1.2种植、养殖、作坊活动成长</p>
      <p><br /></p>
      <p>
        种植类活动从种植到成熟需经历：发芽到丰收等多个阶段，种植作物在不同的阶段所消耗的道具不同（具体以活动页面要求为准），如水滴不够则无法浇水。种植作物所处阶段可参见活动主界面进度条，完成当前进度要求后，会自动成长至下一阶段，用户需主动使用养分道具使作物成长。
      </p>
      <p><br /></p>
      <p>
        养殖类活动从喂养到成熟需经历：幼崽到成年等多个阶段，养殖动物在不同的阶段所消耗的道具不同（具体以活动页面要求为准），如饲料不够则无法成长。养殖动物所处阶段可参见活动主界面进度条，完成当前进度要求后，会自动成长至下一阶段，用户需主动使用饵料道具使动物成长。
      </p>
      <p><br /></p>
      <p>
        作坊类活动从原材料到成品需经历：材料采集到成品等多个阶段，作坊加工在不同的阶段所消耗的道具不同（具体以活动页面要求为准），如材料不够则无法加工生产。作坊加工所处阶段可参见活动主界面进度条，完成当前进度要求后，会自动成长至下一阶段，用户需主动使用材料道具使加工流程更进一步。
      </p>
      <p><br /></p>
      <p>1.3任务说明</p>
      <p><br /></p>
      <p>
        1）用户选择参与的乡村活动后，活动期间每天都可来活动页签到或通过完成观看视频、阅读资讯、浏览广告、打赏、下单等任务领取活动道具。
        每种任务对同一个用户获取的道具数和获取次数存在限制，具体以页面展示为准。
      </p>
      <p><br /></p>
      <p>
        2）用户也可通过分享给好友让好友助力收集道具，分享者和被分享者均有机会获得一定量的乡村活动道具，同一分享者和被分享者之间在本活动期间只可以互相互动助力收集道具。因不同的乡村活动的成长状态不同及其他情况，邀请好友助力获得的奖励可能不同，具体数量请以页面提示为准。若邀请新用户助力并且新用户也开始参与活动后，分享者还有机会获得大额道具奖励，具体奖励请以页面提示为准。
      </p>
      <p><br /></p>
      <p>
        3）下载登录任务。用户通过每个活动的任务栏首次下载并登录指定的客户端，可获得道具奖励，具体数量请以页面提示为准（示例：例如用户通过凯迪网App登录参与本乡村养殖活动，通过任务栏首次下载并登录凯迪网App，可获得牧草道具奖励）。
      </p>
      <p><br /></p>
      <p>
        4）消费时长阶段任务。用户每天可通过在活动任务栏中列明的客户端看视频、读文章、分享文章等不同的任务累计消费时长，满足阶段性奖励条件（具体以活动页面要求为准），用户可获得道具奖励，具体数量请以页面提示为准。
      </p>
      <p><br /></p>
      <p>二、奖励兑换规则</p>
      <p>1. 用户通过完成产品页面列表中任务获得道具，加速种植/养殖/生产进程，当活动项目成熟达到收获期后，即可获得一份奖励。例如，养牛游戏每养殖成功1次，用户仅可获得1份兑换优惠券。</p>
      <p><br /></p>
      <p>
        2.
        奖励形式根据乡村活动不同，形式不同，包括但不限于兑换优惠券、购买资格、购买优惠等奖励形式。例如乡村积分兑换，用户可以使用乡村积分兑换优惠券，兑换完成后，可以在凯迪网App---我的---消息中心---优惠券查看兑换优惠券信息，按照优惠券信息说明引导使用优惠券。优惠券会有有效期限制，若过期失效，即如果用户逾期未使用上述优惠资格，则视为主动放弃权益，平台将不会补发或让用户重新兑换。
      </p>
      <p><br /></p>
      <p>三、奖励发货说明</p>
      <p>1. 用户获得的实物商品，由对应商家负责提供实物商品及售后服务，除本活动规则明确说明外，商品的介绍、产品/发货地、售后等信息以具体商品的页面展示为准。</p>
      <p><br /></p>
      <p>
        2.
        如用户收获后，获得的奖励暂无库存，用户可更换为其他奖励兑换，或选择兑换为价值相近的奖励，也可选择等待补货（到货时间以产品页面提示为准）。若用户选择等待补货，请注意优惠券有效期，及时行使权益。
      </p>
      <p><br /></p>
      <p>
        3. 受实物奖励的特性影响，例如水果保鲜期限制，部分地区无法发货：新疆、西藏、海南、内蒙古、青海、港澳台
        （以商品页面提示为准）；若用户填写收货地址不在可发货地区，则会导致无法购买，用户可更换收货地址再购买。若用户填写地址属于无法发货地区或填写地址有误可能导致用户无法购买或及时收到奖励，相应后果由用户自行承担，请用户根据上述提示选择并填写准确的收货地址。
      </p>
      <p><br /></p>
      <p>四、其他说明</p>
      <p>1. 种植/养殖/作坊类的活动道具仅限于活动过程中使用，可用于浇灌果树或者喂养动物，加速成长，不可用于直接兑换优惠券，不具有实际现金价值、折扣价值或卡券价值，不具备道具外的任何功能。</p>
      <p><br /></p>
      <p>2. 用户所获得的奖励，具体兑换或者使用流程，以商品页面提示为准。不能用于提现，不可以和其他优惠券或红包叠加使用。</p>
      <p><br /></p>
      <p><b>3. 同一用户在本次乡村活动中，可领取的活动奖励、礼品及卡券的价值总计不超过人民币5万元。</b></p>
      <p><br /></p>
      <p>
        4.
        同一个登录账号、同一个手机号、同一个优惠卷领取账号、IP地址等与用户身份相关的信息，其中任意一项或数项存在相同、相似、通过特定标记形成批量信息、或其他非真实有效等情形的，均将被认定为同一用户，按活动规则中的同一用户处理。
      </p>
      <p><br /></p>
      <p>5. 当您进入活动页点击相应按钮发出、接受分享时，我们需要访问您的剪切板并会通过识别口令码、链接等方式以实现跳转、分享等功能或服务，帮您参与活动。</p>
      <p><br /></p>
      <p><b>6. 用户参与活动还应遵守供应商小店平台相关线上用户协议和规则。</b></p>
      <p><br /></p>
      <p><b>五、法律声明：</b></p>
      <p>
        <b
        >1.
          用户不得以任何不正当手段或舞弊方式参与本活动。一经发现，本平台有权采取限制登录、限制参与活动、封禁账号、撤消活动资格、限制领取优惠券、限制再次注册使用等措施，亦有权收回用户已领取的任务奖励、追讨已领取的优惠券、限制水果兑换、联动商家停止发货，并保留追究该用户责任的权利。如因此给用户造成损失，本平台不进行任何赔偿或补偿。</b
        >
      </p>
      <p>
        <b><br /></b>
      </p>
      <p>
        不正当手段及舞弊行为包括但不限于：下载非官方客户端；使用模拟器、插件、外挂等非法工具扫码、下载、安装、注册、登录、赠与、领取任务奖励、提现、参与活动，冒充领取任务奖励；注册多个账号；篡改设备数据；买卖账号、恶意牟利等扰乱平台秩序；作弊非法获利；使用插件、外挂、系统或第三方工具对本平台及本活动进行干扰、破坏、修改或施加其他影响、违反诚实信用及本平台认为的其他不正当手段。
      </p>
      <p><br /></p>
      <p>
        <b
        >2.
          平台依法运营此次活动，如遇因不可抗力、相关情势变更等情况导致本活动暂停举办或无法举办的，平台有权利根据相关法律法规的规定主张免责不可抗力或相关情势变更包括但不限于：活动受到第三方各种不当手段攻击、严重网络攻击或因系统故障、行政机关政策要求、或遇到重大灾害事件导致活动无法举办、暂停或需要调整的。</b
        >
      </p>
      <p>
        <b><br /></b>
      </p>
      <p>
        <b
        >3.
          如因第三方通过各种不当手段攻击、篡改对平台及本活动进行干扰、破坏、修改或施加其他影响，或系统故障致使任务奖励发生错误、优惠券发放错误、用户无法参与或参与失败的，平台无需为此承担任何法律责任。</b
        >
      </p>
      <p>
        <b><br /></b>
      </p>
      <p>
        <b
        >4.
          活动期间，因用户操作不当或用户所在地区网络故障、支付平台网络故障、电信运营商故障、第三方其他平台限制等非本平台所能控制的原因导致的用户无法参与活动、或参与失败，本平台无需为此承担任何法律责任。</b
        >
      </p>
      <p>
        <b><br /></b>
      </p>
      <p>
        <b
        >5.
          请用户务必通过凯迪系产品的手机官方客户端参与本次活动。任何人或第三方以本平台的名义宣称或从事类似活动或恶意发放、转让任务奖励、恶意通知用户中奖、领取任务奖励、欺诈等造成用户损失的，本平台无需为此承担任何法律责任。</b
        >
      </p>
      <p>
        <b><br /></b>
      </p>
      <p>
        <b
        >6.
          在法律法规允许的范围内，平台有权对本活动规则进行变动或调整，相关变动或调整将公布在活动规则页面上，并于公布时即时生效，用户继续参与该活动则视为对前述变动或者调整后的活动规则予以同意。如果用户拒绝上述活动规则的变更或者调整，则应当选择放弃参与变更后的该活动。</b
        >
      </p>
      <p>
        <b><br /></b>
      </p>
      <p>
        <b
        >7.
          任何人不应利用本次活动进行博彩或其他违法行为。对于本平台用户，一经发现，本平台有权取消此用户参与本次活动的资格、收回所获任务奖励或相关奖品。如其行为构成犯罪，本平台有权移交相应司法行政机关处理。如因其行为给本平台造成损失，同时应当承担赔偿责任。</b
        >
      </p>
      <p>
        <b><br /></b>
      </p>
      <p><b>8. 任务奖励发放条件由平台根据活动需要设定，用户已获得的任务奖励仅限于领取优惠券，不能用于在本活动所述平台进行兑换、交易、消费等其他事项。</b></p>
      <p>
        <b><br /></b>
      </p>
      <p>
        <b
        >9.客户端《用户协议》、《隐私政策》同样适用于本次活动，本次活动规则及相关条款与《用户协议》、《隐私政策》相冲突的，以本次活动规则及相关条款为准。本次活动规则及相关条款未约定的内容，仍以客户端《用户协议》、《隐私政策》为准。</b
        >
      </p>
    </div>
    <div class="cattle_footer">
      <div class="back_btn" @click="$router.push('/ruralstrategy')"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
}
</script>

<style scoped lang="less">
.more_raising_cattle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #107c3f;
  overflow-y: scroll;
  .cattle_header {
    width: 100%;
    height: 38px;
    margin-top: 10px;
    background: url('http://cdn.9kd.com/kdnet/rural_strategy_header.png') no-repeat;
    background-size: 100%;
  }
  .cattle_body {
    width: 100%;
    padding: 10px 0;
    background: url('http://cdn.9kd.com/kdnet/cattle_more_body.png') repeat-y fixed;
    background-size: 100%;
    text-align: left;
    p {
      padding: 0 20px;
      color: #399848;
      font-size: 14px;
      line-height: 25px;
      text-align: left;
    }
  }
  .cattle_footer {
    width: 100%;
    height: 163.5px;
    background: url('http://cdn.9kd.com/kdnet/cattle_more_footer.png') no-repeat;
    background-size: 100%;
    background-position: -2px 0;
    .back_btn {
      width: 200px;
      height: 40px;
      margin: 100px auto 0 auto;
      background: url('http://cdn.9kd.com/kdnet/back_to_cattle_btn.png') no-repeat;
      background-size: 100%;
    }
  }
}
</style>
